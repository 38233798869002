import { Slot } from '@radix-ui/react-slot';
import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';
import type { ButtonHTMLAttributes, PropsWithChildren, ReactNode } from 'react';
import { forwardRef, isValidElement } from 'react';
import { cn } from '~/libs/utils';

import { ApplicationIcon } from '~/components/application-icons';

const buttonVariants = cva(
  'inline-flex items-center justify-center rounded-md font-medium text-sm ring-offset-background drop-shadow-sm transition-all hover:drop-shadow focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        default: 'bg-primary text-primary-foreground hover:bg-primary/90',
        success: 'bg-success text-success-foreground hover:bg-success/90',
        accent: 'bg-accent text-accent-foreground hover:bg-accent/90',
        warning: 'bg-warning text-warning-foreground hover:bg-warning/90',
        destructive: 'bg-destructive text-destructive-foreground hover:bg-destructive/90',
        outline: 'border border-input bg-background text-foreground hover:bg-accent hover:text-accent-foreground',
        secondary: 'bg-secondary text-secondary-foreground hover:bg-secondary/80',
        ghost: 'text-accent-foreground hover:text-accent-foreground/60',
        link: 'text-primary underline-offset-4 hover:underline',
      },
      size: {
        sm: 'h-8 px-2 lg:px-3',
        md: 'h-10 px-4',
        lg: 'h-11 px-8',
        icon: 'h-8 w-8 p-2',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'sm',
    },
  }
);
const iconVariants = cva('', {
  variants: {
    size: {
      sm: 'size-4',
      md: 'size-5',
      lg: 'size-6',
      icon: 'size-4',
    },
  },
  defaultVariants: {
    size: 'sm',
  },
});

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  isLoading?: boolean;
  icon?: ReactNode;
  iconSide?: 'left' | 'right';
}

const SlotOrDiv = ({ className, children }: Readonly<PropsWithChildren<{ className?: string }>>) =>
  isValidElement(children) ? (
    <Slot className={className}>{children}</Slot>
  ) : (
    <div className={className}>{children}</div>
  );

const IconLeft = ({
  className,
  isLoading,
  icon,
  iconSide,
}: Readonly<Pick<ButtonProps, 'isLoading' | 'icon' | 'iconSide' | 'className'>>) => {
  if (isLoading) {
    return <ApplicationIcon icon='loading' className={className} />;
  }
  if (icon && iconSide === 'left') {
    return <SlotOrDiv className={className}>{icon}</SlotOrDiv>;
  }
  return null;
};

const IconRight = ({
  className,
  isLoading,
  icon,
  iconSide,
}: Readonly<Pick<ButtonProps, 'isLoading' | 'icon' | 'iconSide' | 'className'>>) => {
  if (isLoading) {
    return null;
  }
  if (icon && iconSide === 'right') {
    return <SlotOrDiv className={className}>{icon}</SlotOrDiv>;
  }
  return null;
};

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, isLoading, icon, iconSide = 'left', asChild = false, children, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button';

    return (
      <Comp
        className={cn(
          buttonVariants({
            variant,
            size,
            className: cn(className, isLoading ? 'disabled' : ''),
          })
        )}
        ref={ref}
        {...props}
      >
        <>
          <IconLeft
            className={cn(iconVariants({ size }), { 'mr-2': children })}
            isLoading={isLoading}
            icon={icon}
            iconSide={iconSide}
          />
          {children}
          <IconRight
            className={cn(iconVariants({ size }), { 'ml-2': children })}
            isLoading={isLoading}
            icon={icon}
            iconSide={iconSide}
          />
        </>
      </Comp>
    );
  }
);
Button.displayName = 'Button';

export { Button, buttonVariants };
