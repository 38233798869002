/* eslint-disable i18next/no-literal-string */
import { CaretSortIcon } from '@radix-ui/react-icons';
import { Slot } from '@radix-ui/react-slot';
import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';
import type { Target } from 'framer-motion';
import { motion } from 'framer-motion';
import {
  Activity,
  AlertTriangle,
  AlignCenter,
  AlignJustify,
  AlignLeft,
  AlignRight,
  Banknote,
  Baseline,
  Bold,
  Brush,
  Building,
  CaseSensitive,
  Check,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  ClipboardCopy,
  CloudMoon,
  CloudSun,
  Code,
  Cog,
  DollarSign,
  Download,
  Euro,
  ExternalLink,
  Eye,
  EyeOff,
  File,
  FileBarChart,
  FileCog,
  FileInput,
  FileOutput,
  FilePieChart,
  FileSpreadsheet,
  FileText,
  FileUp,
  Globe,
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  Heading5,
  Heading6,
  Highlighter,
  Home,
  Italic,
  Laptop2,
  LayoutGrid,
  LineChart,
  Link,
  ListChecks,
  ListIcon,
  ListOrderedIcon,
  Lock,
  LogOut,
  Mail,
  MailCheck,
  MailX,
  MessageSquareMore,
  MinusSquare,
  Pencil,
  Percent,
  Phone,
  Plus,
  PlusSquare,
  Quote,
  Redo,
  Save,
  Search,
  SendHorizontal,
  Settings,
  Strikethrough,
  Subscript,
  SunMoon,
  Superscript,
  Text,
  Trash,
  Underline,
  Undo,
  Unlock,
  User,
  UserCheck,
  UserPlus,
  X,
} from 'lucide-react';
import type { ForwardRefExoticComponent, HTMLAttributes, PropsWithoutRef, RefAttributes } from 'react';
import { forwardRef } from 'react';
import { cn } from '~/libs/utils';

import { Spinner } from '~/components/spinner';

const applicationIconsVariants = cva('m-0 block select-none focus-visible:outline-none', {
  variants: {
    size: {
      sm: 'size-4',
      md: 'size-6',
      lg: 'size-8',
      xl: 'size-10',
    },
  },
  defaultVariants: {
    size: 'sm',
  },
});

export const applicationIcons = {
  message: <MessageSquareMore />,
  graph: <LineChart />,
  'light-mode': <CloudSun />,
  'dark-mode': <CloudMoon />,
  'external-link': <ExternalLink />,
  save: <Save />,
  close: <X />,
  file: <File />,
  'file-upload': <FileUp />,
  add: <Plus />,
  'user-plus': <UserPlus />,
  'user-check': <UserCheck />,
  application: <LayoutGrid />,
  administration: <Cog />,
  technical: <Settings />,
  home: <Home />,
  theme: <SunMoon />,

  logout: <LogOut />,
  left: <ChevronLeft />,
  right: <ChevronRight />,
  down: <ChevronDown />,
  up: <ChevronUp />,

  login: <Unlock />,
  copy: <ClipboardCopy />,
  password: <Lock />,
  percent: <Percent />,
  document: <File />,
  success: <Check />,
  download: <Download />,
  view: <Eye />,
  hide: <EyeOff />,
  loading: <Spinner />,
  error: <AlertTriangle />,
  edit: <Pencil />,
  phone: <Phone />,
  delete: <Trash />,
  history: <Activity />,

  search: <Search />,

  'credit-note': <FileOutput />,
  invoice: <FileOutput />,
  estimate: <FileOutput />,
  customer: <Building />,
  expense: <FileInput />,
  article: <FileText />,
  configuration: <Settings />,
  'vat-type': <FileSpreadsheet />,
  'vat-amount': <FileBarChart />,
  'vat-rate': <FilePieChart />,
  'invoice-status': <FileCog />,
  'estimate-status': <FileCog />,
  'credit-note-status': <FileCog />,
  currency: <Banknote />,
  euro: <Euro />,
  dollar: <DollarSign />,
  user: <User />,
  email: <Mail />,

  undo: <Undo />,
  redo: <Redo />,
  quote: <Quote />,
  code: <Code />,
  paragraph: <Text />,
  h1: <Heading1 />,
  h2: <Heading2 />,
  h3: <Heading3 />,
  h4: <Heading4 />,
  h5: <Heading5 />,
  h6: <Heading6 />,
  bullet: <ListIcon />,
  check: <ListChecks />,
  number: <ListOrderedIcon />,

  'text-format': <Baseline />,
  bold: <Bold />,
  italic: <Italic />,
  underline: <Underline />,
  strikethrough: <Strikethrough />,
  // eslint-disable-next-line jsx-a11y/anchor-has-content
  link: <Link />,
  subscript: <Subscript />,
  superscript: <Superscript />,
  'left-align': <AlignLeft />,
  'right-align': <AlignRight />,
  'center-align': <AlignCenter />,
  'justify-align': <AlignJustify />,
  'case-sensitive': <CaseSensitive />,
  brush: <Brush />,
  highlighter: <Highlighter />,
  system: <Laptop2 />,
  send: <SendHorizontal />,
  'combo-open': <CaretSortIcon />,
  'plus-square': <PlusSquare />,
  'minus-square': <MinusSquare />,
  'mail-sent': <MailCheck />,
  'mail-not-sent': <MailX />,
  language: <Globe />,
  'menu-open': (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <title className='sr-only'>Menu open</title>
      <path d='m18 9-6-6-6 6' />
      <path d='m6 15 6 6 6-6' />
    </svg>
  ),
  'panel-close': (
    <motion.svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
      animate={{ '--x': 10, '--opacity': 0 } as Target}
      whileHover={
        {
          '--x': 0,
          '--opacity': 1,
        } as Target
      }
    >
      <title className='sr-only'>Panel close</title>
      <rect width='18' height='18' x='3' y='3' rx='2' ry='2' />
      <line x1='9' x2='9' y1='3' y2='21' />

      <motion.path d='m16 15-3-3 3-3' style={{ x: 'var(--x)', opacity: 'var(--opacity)' }} />
    </motion.svg>
  ),

  'panel-open': (
    <motion.svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
      animate={{ '--x': 10, '--opacity': 0 } as Target}
      whileHover={
        {
          '--x': 0,
          '--opacity': 1,
        } as Target
      }
    >
      <title className='sr-only'>Panel open</title>
      <rect width='18' height='18' x='3' y='3' rx='2' ry='2' />
      <line x1='9' x2='9' y1='3' y2='21' />

      <motion.path d='m14 9 3 3-3 3' style={{ x: 'var(--x)', opacity: 'var(--opacity)' }} />
    </motion.svg>
  ),

  microsoft: (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'>
      <title className='sr-only'>Microsoft logo</title>
      <path d='M0 32h214.6v214.6H0V32zm233.4 0H448v214.6H233.4V32zM0 265.4h214.6V480H0V265.4zm233.4 0H448V480H233.4V265.4z' />
    </svg>
  ),
  google: (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 488 512'>
      <title className='sr-only'>Google logo</title>
      <path d='M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z' />
    </svg>
  ),
} as const;

export type ApplicationIconProps = VariantProps<typeof applicationIconsVariants> & {
  icon: AppIconNames;
} & HTMLAttributes<HTMLElement>;

const Icon: ForwardRefExoticComponent<PropsWithoutRef<ApplicationIconProps> & RefAttributes<HTMLElement>> = forwardRef<
  HTMLElement,
  ApplicationIconProps
>(({ size, icon, className, ...props }, ref) => (
  <Slot tabIndex={-1} className={cn(applicationIconsVariants({ size }), className)} ref={ref} {...props}>
    {applicationIcons[icon]}
  </Slot>
));
Icon.displayName = 'Icon';

export const ApplicationIcon = motion.create(Icon) as unknown as ForwardRefExoticComponent<
  PropsWithoutRef<ApplicationIconProps> & RefAttributes<HTMLElement>
>;
export type AppIcons = typeof applicationIcons;
export type AppIconNames = keyof AppIcons;
